var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"elevation-1 myTable",attrs:{"headers":_vm.terminalHeaders,"items":_vm.all_transactions,"single-select":false,"item-key":"id","show-select":"","search":_vm.search,"loading":_vm.fetchTransactions,"loading-text":"Loading data... Please wait"},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-simple-checkbox',_vm._g({attrs:{"value":props.value || props.indeterminate,"indeterminate":props.indeterminate,"color":"white","light":true,"ripple":false}},on))]}},{key:"header.actions",fn:function(){return [_c('v-menu',{staticClass:"ma-0",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var dropdown = ref.dropdown;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white"}},'v-btn',dropdown,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"text":"","color":"primary","block":""},on:{"click":function($event){return _vm.acceptReuest(_vm.item)}}},[_vm._v("Suspend")])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"text":"","color":"primary","block":""},on:{"click":function($event){return _vm.rejectReuest(_vm.item)}}},[_vm._v("Delete")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var dropdown = ref.dropdown;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',dropdown,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"primary--text",staticStyle:{"cursor":"pointer"},attrs:{"text":""},on:{"click":function($event){return _vm.agentSettings()}}},[_vm._v("Fund Terminal")])],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"primary--text",staticStyle:{"cursor":"pointer"},attrs:{"text":""},on:{"click":function($event){return _vm.assignAgent(item)}}},[_vm._v("Stop Terminal")])],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"primary--text",staticStyle:{"cursor":"pointer"},attrs:{"text":""},on:{"click":function($event){return _vm.processReuest(item)}}},[_vm._v("Delete")])],1)],1)],1)]}},{key:"item.numbers",fn:function(ref){
var index = ref.index;
return [_c('div',[_vm._v(_vm._s(index + 1))])]}},{key:"item.transactionDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(item.transactionDate)))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("₦ "+_vm._s(_vm._f("formatPrice")(item.amount)))])]}},{key:"item.commission_amount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("₦ "+_vm._s(item.commission_amount))])]}},{key:"item.reversal",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"secondary--text border",attrs:{"small":"","label":"","color":"white"}},[_vm._v(_vm._s(item.reversal == 0 ? 'Successful' : 'Failed'))])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }