<template>
	<div>
		<v-row>
			<v-col cols="12">
				<v-card outlined>
					<v-data-table
						v-model="selected"
						:headers="terminalHeaders"
						:items="all_transactions"
						:single-select="false"
						item-key="id"
						show-select 
						:search="search"
						class="elevation-1 myTable"
						:loading="fetchTransactions"
						loading-text="Loading data... Please wait"
					>
						<template v-slot:[`header.data-table-select`]="{ props, on }">
							<v-simple-checkbox
								:value="props.value || props.indeterminate"
								v-on="on"
								:indeterminate="props.indeterminate"
								color="white"
								:light="true"
								:ripple="false"
							/>
						</template>
						<template v-slot:[`header.actions`]>
							<v-menu class="ma-0">
								<template v-slot:activator="{ on, dropdown }">
									<v-btn
										icon
										color="white"
										v-bind="dropdown"
										v-on="on"
									>
										<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
								</template>

								<v-list>
									<v-list-item>
										<v-list-item-title>
											<v-btn
												text
												color="primary"
												block
												@click="acceptReuest(item)"
											>Suspend</v-btn>
										</v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title>
											<v-btn
												text
												color="primary"
												block
												@click="rejectReuest(item)"
											>Delete</v-btn>
										</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</template>

						<template v-slot:[`item.actions`]="{ item }">
							<v-menu>
								<template v-slot:activator="{ on, dropdown }">
									<v-btn
										icon
										color="primary"
										v-bind="dropdown"
										v-on="on"
									>
										<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
								</template>

								<v-list>
									<v-list-item>
										<v-list-item-title 
											style="cursor: pointer"
											text
											class="primary--text"
											@click="agentSettings()"
										>Fund Terminal</v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title 
											style="cursor: pointer"
											text
											class="primary--text"
											@click="assignAgent(item)"
										>Stop Terminal</v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title 
											style="cursor: pointer"
											text
											class="primary--text"
											@click="processReuest(item)"
										>Delete</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</template>
						<template v-slot:[`item.numbers`]="{ index }">
							<div>{{ index + 1 }}</div>
						</template>
						<template v-slot:[`item.transactionDate`]="{ item }">
							<div>{{ item.transactionDate | formatDate }}</div>
						</template>
						<template v-slot:[`item.amount`]="{ item }">
							<div>₦ {{ item.amount | formatPrice }}</div>
						</template>
						<template v-slot:[`item.commission_amount`]="{ item }">
							<div>₦ {{ item.commission_amount }}</div>
						</template>
						<template v-slot:[`item.reversal`]="{ item }">
							<v-chip small label color="white" class="secondary--text border">{{ item.reversal == 0 ? 'Successful' : 'Failed' }}</v-chip>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
		<!-- <assign-agent ref="assignAgent"></assign-agent> -->
	</div>
</template>

<script>
import { mapActions } from 'vuex'
// import assignAgent from './dialogs/assignAgent'
export default {
	components: {
		// assignAgent,
	},

	props: {
		user_id: String,
		search: String,
		transactions: Array,
	},

	data() {
		return {
			loading: false,
			selected: [],
			terminalHeaders: [
				{ 
					text: 'S/N', 
					value: 'numbers', 
					sortable: false, 
					orderable: false,
				},
				{ text: 'Reference', value: 'reference' },
				{ text: 'Type', value: 'transactionType' },
				{ text: 'Date', value: 'transactionDate' },
				{ text: 'Total Amount', value: 'amount' },
				// { text: 'Recipient', value: 'recipient' },
				{ text: 'Terminal', value: 'terminalId' },
				{ text: 'Commission', value: 'commission_amount' },
				{ text: 'Status', value: 'reversal' },
				// { text: 'Filter', value: 'actions', sortable: false, orderable: false }
			],
			all_transactions: this.transactions,
			fetchTransactions: false,
		}
	},

	mounted() {
		// this.agentTransactions()
		this.$root.$on('filteredtransactions', (data) => {
			// let res = data.filter(item => {
			// 	if (item.id) {
			// 		return item
			// 	}
			// })
			this.all_transactions = data.transactions

			// let trns = data.filter(item => {
			// 	if (item.transactions) {
			// 		return item
			// 	}
			// })

			this.$parent.total_transactions 	= data.totalTxCount
			this.$parent.total_amount					= data.totalTxAmount
			this.$parent.daily_amount					= 0
			this.$parent.weekly_amount				= 0
			this.$parent.monthly_amount				= 0
			this.$parent.daily_transactions 	= 0
			this.$parent.weekly_transactions	= 0
			this.$parent.monthly_transactions	= 0
		})
	},

	methods: {
		...mapActions('pos', [
			'filter_terminal_transactions'
		]),

		assignAgent(item) {
			console.log(item)
			this.$refs.assignAgent.openDialog()
		},

		async filterTransactions(value) {
			let data = {
				terminal_id: value.terminal_id,
				filter: value.range,
				target: 'terminal_transactions'
			}
			this.fetchTransactions = true
			try {
				let response = await this.filter_terminal_transactions(data)
				this.fetchTransactions = false
				this.all_transactions = response.data.transactions
				this.$parent.total_transactions 	= response.data.total_transactions
				this.$parent.total_amount					= response.data.total_amount
				this.$parent.daily_amount					= response.data.daily_amount
				this.$parent.weekly_amount				= response.data.weekly_amount
				this.$parent.monthly_amount				= response.data.monthly_amount
				this.$parent.daily_transactions 	= response.data.daily_transactions
				this.$parent.weekly_transactions	= response.data.weekly_transactions
				this.$parent.monthly_transactions	= response.data.monthly_transactions
			} catch (error) {
				this.fetchTransactions = false
				this.$toast.error(error.response.data.message)
			}
		}
	}
}
</script>